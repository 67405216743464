
import request from '@/utils/request'

export function uploadFile (data, config) {
  return request({
    url: '/api-manager/file',
    method: 'post',
    data,
    ...config,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function deleteFile (id) {
  return request({
    url: `/api-manager/file/${id}`,
    method: 'delete'
  })
}

export function getFile (id) {
  return request({
    url: `/api-manager/file/${id}`,
    method: 'get'
  })
}
