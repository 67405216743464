<template>
  <div class="app-container">
  <el-row :gutter="4" type="flex" justify="center">
    <el-col :span="20">
      <el-card class="box-card">
        <div slot="header" class="clearfix" justify="center" align="center">
          <strong><span>Editar Guía</span></strong>
        </div>
        <div class="text item">
          <ValidationObserver ref="form" v-slot="{ invalid }">
            <el-form label-position="top" class="size-form-item" label-width="120px" :model="form">
              <el-row justify="space-around" align="middle">
                <el-col :sm="24" :md="24" :lg="24" align="middle">
                  <el-form-item label="Imagen de Guía">
                    <ImageUpload
                      :postImageTo="postImageTo"
                      :getImagesFrom="getGallery"
                      :setImage="form.image"
                      @getImage="getSelectedImage"
                    ></ImageUpload>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row justify="space-around" align="middle">
                <el-col :sm="24" :md="24" :lg="24" align="middle">
                  <el-form-item label="Mostrar en aplicación" style="display: inline-block; margin: 0 0.8em 0 0.8em;">
                    <el-switch
                      v-model="form.showInApp"
                      active-text="Si"
                      inactive-text="No">
                    </el-switch>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row justify="space-around" align="middle">
                <el-col :sm="24" :md="24" :lg="24" align="middle">
                  <el-form-item style="display: inline-block; margin: 0 1em 0 0.8em;">
                    <el-upload
                      :show-file-list="true"
                      :http-request="handleUploadFile"
                      :before-upload="beforeUploadFile"
                      :on-remove="handleRemoveFile"
                      :auto-upload="true"
                      :on-exceed="handleExceed"
                      :file-list="fileList"
                      :limit="1"
                      action="#"
                      class="upload-demo">
                      <el-button size="mini" type="primary" plain>Subir Guía</el-button>
                    </el-upload>
                  </el-form-item>
                  <div v-if="fileIsLoading">
                    <p style="font-size: 0.8rem; ">Cargando archivo...</p>
                  </div>
                </el-col>
              </el-row>
              <el-row justify="space-around" align="left">
                <el-col :sm="24" :md="24" :lg="24" align="left">
                  <el-form-item label="Título">
                    <ValidationProvider name="title" rules="required" v-slot="{ errors }">
                      <el-input size="mini" v-model="form.title"></el-input>
                      <span>{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row justify="space-around" align="left">
                <el-col :sm="24" :md="24" :lg="24" align="left">
                  <el-form-item label="Nombre de Clave">
                    <ValidationProvider name="Nombre de Clave" rules="required" v-slot="{ errors }">
                      <el-input disabled size="mini" v-model="form.keyName"></el-input>
                      <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row justify="space-around" align="left">
                <el-col :sm="24" :md="24" :lg="24" align="left">
                  <el-form-item label="Sección">
                    <ValidationProvider  name="Seccion" rules="required" v-slot="{ errors }">
                      <el-select
                        v-model="form.section"
                        placeholder="Selecciona un elemento">
                        <el-option
                          v-for="item in sections"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                      <br>
                      <span style="color: #F56C6C; font-size: 12px;">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row justify="space-around" align="left">
                <el-col :sm="24" :md="24" :lg="24" align="left">
                  <el-form-item label="Categoria">
                    <ValidationProvider name="Categoría" rules="required" v-slot="{ errors }">
                      <el-select
                        v-model="form.category"
                        clearable
                        filterable
                        allow-create
                        placeholder="Escribe o selecciona un elemento">
                        <el-option
                          v-for="item in categories"
                          :key="item.category"
                          :label="item.category"
                          :value="item.category">
                        </el-option>
                      </el-select>
                      <br>
                      <span style="color: #F56C6C; font-size: 12px;">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row justify="space-around" align="left">
                <el-col :sm="24" :md="24" :lg="24" align="left">
                  <el-form-item label="Patrocinador">
                    <ValidationProvider  name="Patrocinador" rules="" v-slot="{ errors }">
                      <el-select
                        v-model="form.sponsor"
                        clearable
                        filterable
                        allow-create
                        placeholder="Escribe o selecciona un elemento">
                        <el-option
                          v-for="item in sponsors"
                          :key="item.sponsor"
                          :label="item.sponsor"
                          :value="item.sponsor">
                        </el-option>
                      </el-select>
                      <br>
                      <span style="color: #F56C6C; font-size: 12px;">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label="Duración Lectura (minutos)">
                <ValidationProvider name="Duración" rules="required" v-slot="{ errors }">
                  <el-input-number v-model="form.duration" :min="1"></el-input-number>
                  <span style="color: #F56C6C;">{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-row justify="space-around" align="left">
                <el-col :sm="24" :md="24" :lg="24" left>
                  <el-form-item label="Descripción">
                    <ValidationProvider name="Description" rules="required" v-slot="{ errors }">
                      <el-input type="textarea" :rows="4" v-model="form.description"></el-input>
                      <span>{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row >
                <el-col >
                  <!--<el-row>
                    <el-col>
                      <el-form-item>
                        <el-button
                          class="preview-button"
                          @click.prevent="callPreviewService"
                          type="danger">
                          Preview
                        </el-button>
                      </el-form-item>
                    </el-col>
                  </el-row>-->
                  <el-form-item label="HTML" style="width: 100% !important;">
                    <ValidationProvider name="html" rules="required" v-slot="{ errors }">
                      <CKEditor :setContent="form.content" :key="form.content" @getContent="getUpdatedContent"></CKEditor>
                      <span style="color: #F56C6C;">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" justify="center">
                <el-col :xs="8" :md="4" align="middle">
                  <el-form-item>
                    <el-button
                      @click.prevent="handlerUpdate"
                      type="primary"
                      icon="el-icon-upload2"
                      :disabled="invalid">
                      Actualizar
                    </el-button>
                  </el-form-item>
                </el-col>
                <el-col :xs="8" :md="4" align="middle">
                  <el-form-item>
                    <el-button
                      @click.prevent="handlerCancel"
                      type="danger">
                      Cancelar
                    </el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </ValidationObserver>
        </div>
      </el-card>
    </el-col>
  </el-row>
  </div>
</template>

<script>
// import _ from 'lodash'
import { uploadImageFile } from '@/api/image.js'
import { search } from '@/api/gallery.js'
import ImageUpload from '@/components/imageUpload/ImageUpload.vue'
import { getGuide, updateGuide, filterData } from '@/api/guides.js'
import { uploadFile, deleteFile } from '@/api/files.js'
import CKEditor from '@/components/CKEditor/CKEditor.vue'
const confElement = document.getElementById('config')
const conf = JSON.parse(confElement.innerHTML)
const urlServer = conf.baseApi

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  name: 'editGuide',
  components: {
    ImageUpload,
    CKEditor
  },
  data () {
    return {
      fileIsLoading: false,
      host: urlServer,
      form: {
        title: '',
        description: '',
        image: null,
        file: null,
        showInApp: false,
        duration: 1,
        section: '',
        category: '',
        keyName: '',
        content: '',
        preview: null
      },
      beforePathFile: '',
      fileId: '',
      fileList: [],
      loadingImage: false,
      imageSelected: false,
      fileSelected: false,
      backupForm: '',
      sections: [
        {
          label: 'Primera Sección',
          value: '1'
        },
        {
          label: 'Segunda Sección',
          value: '2'
        },
        {
          label: 'Tercera Sección',
          value: '3'
        },
        {
          label: 'Cuarta Sección',
          value: '4'
        }
      ],
      categories: [],
      sponsors: [],
      contentEditor: ''
    }
  },
  mounted () {
    // idProduct = this.$route.query.id
    this.fetchFilterSponsors()
    this.fetchFilterCategories()
    this.fetchData()
  },
  methods: {
    callPreviewService () {
      window.open(this.form.preview, '_blank')
    },
    async postImageTo (param) {
      return await uploadImageFile(param)
    },
    async getGallery (param) {
      return await search(param)
    },
    getSelectedImage (imageUploaded) {
      this.form.image = imageUploaded
      console.log('imagen cargada en formulario ' + this.form.image)
    },
    handleExceed () {
      this.$message.warning('Ya haz cargado un archivo. Por favor elimina primero el archivo cargado')
    },
    async fetchFilterSponsors () {
      const formData = new FormData()
      formData.append('fieldSearch', 'sponsor')
      filterData(formData)
        .then((response) => {
          this.sponsors = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async fetchFilterCategories () {
      const formData = new FormData()
      formData.append('fieldSearch', 'category')
      filterData(formData)
        .then((response) => {
          this.categories = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleRemoveFile (file) {
      console.log('borrar  ' + file)
      if (this.fileId === undefined || this.fileId === '') {
        this.fileList = []
      } else {
        try {
          const response = await deleteFile(this.fileId)
          if (response.success) {
            this.form.file = ''
            this.fileId = ''
            this.fileList = []
          }
        } catch (err) {
          console.log(err)
          this.fileList = [file]
        }
      }
    },
    async fetchData () {
      try {
        console.log(this.id)
        const response = await getGuide(this.id)
        if (response.success) {
          console.log(response)
          this.backupForm = JSON.parse(JSON.stringify(response.data))
          this.form = await response.data
          this.contentEditor = this.form.content
          this.beforePathFile = await response.data.file
          this.fileList = [{
            name: this.form.file.slice('storage/files/'.length),
            url: this.form.file
          }]
        } else {
          console.log(response)
        }
      } catch (err) {
        this.$message.error({
          type: 'info',
          message: 'Ha ocurrido un error al obtener los datos'
        })
      }
    },
    async handlerCancel () {
      if (this.form.file !== '' && this.form.file !== null && this.fileId !== '' && this.fileId !== null) {
        try {
          await deleteFile(this.fileId)
          this.form.file = this.beforePathFile
        } catch (err) {
          console.log(err)
        }
      }
      this.$router.push('/guides')
    },
    async handlerUpdate (info) {
      this.form.content = this.contentEditor
      if (this.form.image !== '' && this.form.file !== '' && this.form.image !== null && this.form.file !== null) {
        try {
          const response = await updateGuide(this.form, this.form._id)
          if (response.success) {
            console.log(response)
            this.$message({
              showClose: true,
              message: '¡Se actualizó correctamente!',
              type: 'success'
            })
            this.$router.push('/guides')
          } else {
            console.log(response)
          }
        } catch (err) {
          err.response.data.messages.forEach(message => {
            this.$message.error(`${message}`)
          })
        }
        // if (_.isEqual(this.form, this.backupForm)) {
        //   this.$router.push('/guides')
        // } else {
        // }
      } else {
        this.$message.error({
          type: 'warning',
          message: 'Ha ocurrido un error'
        })
      }
    },
    // ----- Upload of Files -----
    async handleUploadFile (param) {
      console.log(param.file.name)
      this.loading = true
      const formData = new FormData()
      formData.append('file', param.file)
      const randomName = Math.floor(Math.random() * 100000)
      formData.append('name', (param.file.name.replace('.pdf', `_${randomName}`)))
      this.fileIsLoading = true
      uploadFile(formData)
        .then((response) => {
          console.log(response)
          param.onSuccess(response)
          this.$message({
            showClose: true,
            message: 'El archivo se cargó correctamente',
            type: 'success'
          })
          console.log(response.data.path)
          this.fileId = response.data._id
          this.form.file = response.data.path
          this.fileSelected = true
        })
        .catch((err) => {
          console.log(err.response.status)
          if (err.response.status === 413) {
            this.$message({
              showClose: true,
              message: 'El archivo a cargar rebasa el limite de peso, cargue uno dentro del límite.',
              type: 'warning'
            })
          }
          const data = err.response.data
          console.log('err -> data', data)
          if (data.messages) {
            data.messages.forEach((element) => {
              this.$message.error(element)
            })
          }
        })
        .finally(() => {
          this.loading = false
          this.fileIsLoading = true
        })
    },
    beforeUploadFile (file) {
      const LENGTH_PDF = 43
      const isPDF = file.type === 'application/pdf'
      console.log('file size = ' + file.size)
      const isLt40M = file.size / 1024 / 1024 < LENGTH_PDF
      var flag = true

      if (!isPDF) {
        this.$message.error('¡El archivo debe ser un PDF')
        flag = false
      }
      if (!isLt40M) {
        this.$message.error('¡El archivo excede los 40MB!')
        flag = false
      }
      return flag && isLt40M
    },
    getUpdatedContent (content) {
      this.contentEditor = content
    }
  }
}
</script>

<style>

 .size-form-item > .el-form-item {
  margin: 0;
}
.size-form-item > .el-form--label-top .el-form-item__label {
  padding: 0;
  margin-bottom: 0;
}
.my-autocomplete {
  width: 500px;
}

.preview-button {
  position: absolute;
  top: 100;
  right: 0;
  margin: 12px;
}
</style>
