import request from '@/utils/request'
export function search (params) {
  return request({
    url: '/api-manager/guide/search',
    method: 'post',
    params
  })
}
export function getPage (page, noitems, params) {
  return request({
    url: '/api-manager/guide/search?page=' + page + '&item=' + noitems,
    method: 'post',
    params
  })
}

export function getGuide (id) {
  return request({
    url: `/api-manager/guide/${id}`,
    method: 'get'
  })
}

export function createGuide (data) {
  return request({
    url: '/api-manager/guide',
    method: 'post',
    data
  })
}

export function updateGuide (data, id) {
  return request({
    url: `/api-manager/guide/${id}`,
    method: 'put',
    data
  })
}

export function deleteGuide (id) {
  return request({
    url: `/api-manager/guide/${id}`,
    method: 'delete'
  })
}

export function filterData (data) {
  return request({
    url: '/api-manager/guide/filterData',
    method: 'post',
    data
  })
}
